<template>
    <div id="enclosingContainer">
        <div
            v-if="error"
            id="generatingReportContainer"
            class=""
            :class="{
                reportContainerErrorEmbedded: embeddedVal && error,
            }"
        >
            <h1 id="generatingReportError" class="text-primary">
                {{ t('errorGeneratingReport') }}
            </h1>
        </div>
        <div id="pageContainer" v-if="!error && !hideReport">
            <SpinnerOverlay v-if="!embedded && !error && !debug" class="z-index-999" />

            <b-button id="printReportBtn" v-if="debug" @click="printReport">Print Report</b-button>

            <div v-if="!embedded && !error && !debug" id="generatingReportContainer">
                <h1 id="generatingReport" class="text-primary">{{ t('generatingReport') }}</h1>
            </div>

            <!-- DISABLE DEBUG
            <div v-if="debugVal" id="debugForm">
                <div class="formItem">
                    <div>Debugging Utils</div>
                </div>

                <div class="formItem">
                    <label for="osCheckbox">Hide Patient Name</label>
                    <input
                        id="osCheckbox"
                        type="checkbox"
                        v-model="activeCustomer.hidePatientNames"
                    />
                </div>

                <div class="formItem">
                    <label for="osCheckbox">Display OD</label>
                    <input id="osCheckbox" type="checkbox" v-model="isOd" />
                </div>

                <div class="formItem">
                    <label for="isToric">Is Toric</label>
                    <input id="isToric" type="checkbox" v-model="iodReport.eye.isToric" />
                </div>

                <div class="formItem">
                    <label for="primary">Is Primary</label>
                    <input id="primary" type="checkbox" v-model="iodReport.eye.primary" />
                </div>

                <div class="formItem">
                    <label for="piRequired">PI Required</label>
                    <input id="piRequired" type="checkbox" v-model="iodReport.eye.piRequired" />
                </div>

                <div class="formItem">
                    <label for="lengthDiff">lengthDiff</label>
                    <input id="lengthDiff" type="checkbox" v-model="iodReport.eye.lengthDiff" />
                </div>

                <div class="formItem">
                    <label for="serialNum">Serial #</label>
                    <input id="serialNum" type="input" v-model="iodReport.eye.serialNum" />
                </div>

                <div class="formItem">
                    <label for="model">Model</label>
                    <input id="model" type="input" v-model="iodReport.eye.model" />
                </div>

                <div class="formItem">
                    <label for="version">Version</label>
                    <input id="version" type="input" v-model="iodReport.eye.version" />
                </div>

                <div class="formItem">
                    <label for="sphere">Sphere</label>
                    <input id="sphere" type="input" v-model="iodReport.eye.sphere" />
                </div>

                <div class="formItem">
                    <label for="cylinder">Cylinder</label>
                    <input id="cylinder" type="input" v-model="iodReport.eye.cylinder" />
                </div>

                <div class="formItem">
                    <label for="axis">Axis</label>
                    <input id="axis" type="input" v-model="iodReport.eye.axis" />
                </div>

                <div class="formItem">
                    <label for="expRef">Exp Ref</label>
                    <input id="expRef" type="input" v-model="iodReport.eye.expRef" />
                </div>

                <div class="formItem">
                    <label for="expSeq">Exp. SEQ</label>
                    <input id="expSeq" type="input" v-model="iodReport.eye.expSeq" />
                </div>

                <div class="formItem">
                    <label for="targetLensSphere">targetLensSphere</label>
                    <input
                        id="targetLensSphere"
                        type="input"
                        v-model="iodReport.eye.targetLensSphere"
                    />
                </div>

                <div class="formItem">
                    <label for="targetLensCylinder">targetLensCylinder</label>
                    <input
                        id="targetLensCylinder"
                        type="input"
                        v-model="iodReport.eye.targetLensCylinder"
                    />
                </div>

                <div class="formItem">
                    <label for="targetLensAxis">targetLensAxis</label>
                    <input
                        id="targetLensAxis"
                        type="input"
                        v-model="iodReport.eye.targetLensAxis"
                    />
                </div>

                <div class="formItem">
                    <label for="targetLensExpSeq">targetLensExpSeq</label>
                    <input
                        id="targetLensExpSeq"
                        type="input"
                        v-model="iodReport.eye.targetLensExpSeq"
                    />
                </div>

                <div class="formItem">
                    <label for="highlightedKeys">Highlighted Keys</label>
                    <input id="highlightedKeys" type="input" v-model="highlightedKeys" />
                </div>

                <div class="formItem">
                    <div>targetLensAxis (0-360)</div>
                    <b-form-spinbutton
                        v-model="iodReport.eye.targetLensAxis"
                        min="0"
                        max="360"
                    ></b-form-spinbutton>
                </div>

                <div class="formItem">
                    <div>ordered lens axis (0-360)</div>
                    <b-form-spinbutton
                        v-model="iodReport.eye.axis"
                        min="0"
                        max="360"
                    ></b-form-spinbutton>
                </div>
            </div>
 -->
            <div
                v-if="iodReport"
                id="main"
                :class="{
                    mainScale: embeddedVal,
                }"
            >
                <div id="pageHeader">
                    <img id="headerImg" src="@/assets/stella-logo-with-name.png" />

                    <div v-if="iodReport.eye.isToric" id="headerRight">
                        {{ t('iodreport_Title') }}
                    </div>

                    <div v-else id="headerRight">{{ t('iodreport_SphericImpDiagram') }}</div>
                </div>

                <div id="titleContainer" class="d-flex justify-content-between">
                    <section>
                        <h2 class="mb-0 font-weight-bold">
                            {{ definedAndNotBlankObj(iodReport, 'patientOcosId', '—') }}
                            <span class="h5 text-muted" v-if="hasPatientPersonalDataPermission">
                                {{ genderHandling(iodReport) }}
                            </span>
                        </h2>
                        <p class="h6 text-muted">
                            {{ t('patientId') }}
                        </p>
                    </section>
                    <section
                        v-if="!activeCustomer.hidePatientNames && hasPatientPersonalDataPermission"
                    >
                        <h2 class="mb-0 names text-right">
                            {{
                                namesFormatter(
                                    definedAndNotBlankObj(iodReport, 'lastName', ''),
                                    definedAndNotBlankObj(iodReport, 'firstName', '')
                                ) || '&nbsp;'
                            }}
                        </h2>
                        <p class="h6 text-muted text-right">
                            {{ t('NameLastFirst') }}
                        </p>
                    </section>
                </div>

                <div id="patientDataContainer">
                    <div id="patientDataCol1">
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Birthdate') }}</div>
                            <div id="patientDataDOB" class="patientDataValue">
                                {{ date(iodReport.dob, {isUTC: false}) || '-' }}
                                <span class="noEmphasis">
                                    ({{ definedAndNotBlankObj(iodReport, 'yearsOld', '—') }}
                                    {{ t('preopreport_years') }})
                                </span>
                            </div>
                        </div>
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Customer') }}</div>
                            <div id="patientDataClinicName" class="patientDataValue">
                                {{ definedAndNotBlankObj(iodReport, 'customerName', '—') }}
                                <span class="noEmphasis">
                                    ({{ definedAndNotBlankObj(iodReport, 'customerOcosId', '—') }})
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="patientDataCol2">
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Surgeon') }}</div>
                            <div id="patientDataSurgeon" class="patientDataValue">
                                {{ definedAndNotBlankObj(iodReport, 'surgeon', '—') }}
                                <span class="noEmphasis">
                                    ({{ definedAndNotBlankObj(iodReport, 'surgeonOcosId', '—') }})
                                </span>
                            </div>
                        </div>
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_SurgeryDate') }}</div>
                            <div id="patientDataSurgeryDate" class="patientDataValue">
                                {{ date(iodReport.surgeryDate) || '—' }}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="resultsContainer">
                    <div class="eyeContainer">
                        <div class="eyeResult">
                            <div class="eyeResultHeader">
                                <div id="odEyeTitle" class="eyeTitle">{{ iodReport.eye.name }}</div>
                                <img
                                    id="odEye"
                                    v-if="iodReport.eye.name == 'OD'"
                                    class="eyeResultHeaderImg"
                                    src="@/assets/eye_icon_black.svg"
                                />

                                <img
                                    id="odEye"
                                    v-else
                                    class="eyeResultHeaderImg"
                                    src="@/assets/eye_icon_gray.svg"
                                />

                                <img
                                    id="sdEye"
                                    v-if="iodReport.eye.name == 'OS'"
                                    class="eyeResultHeaderImg"
                                    src="@/assets/eye_icon_black.svg"
                                />

                                <img
                                    id="sdEye"
                                    v-else
                                    class="eyeResultHeaderImg"
                                    src="@/assets/eye_icon_gray.svg"
                                />

                                <div
                                    :class="{
                                        previousInterventionsContainer: true,
                                        previousInterventionsContainerSID: !iodReport.eye.isToric,
                                    }"
                                >
                                    <!-- <template
                                        v-if="
                                            iodReport.eye.previousInterventions &&
                                            iodReport.eye.previousInterventions.length
                                        "
                                    >
                                        <div
                                            v-for="pi in iodReport.eye.previousInterventions"
                                            :key="pi"
                                        >
                                            {{ pi }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>{{ t('preopreport_NoPI') }}</div>
                                    </template> -->
                                </div>

                                <div id="eyeResultHeaderText3">{{ t('targetLens') }}</div>
                                <div id="eyeResultHeaderText4">
                                    {{ targetLensDescription }}
                                </div>
                                <div
                                    id="eyeResultHeaderText5"
                                    :class="{eyeResultHeaderText5SID: !iodReport.eye.isToric}"
                                >
                                    {{ targetLensPrescription }}
                                </div>
                            </div>

                            <div v-if="iodReport.eye" id="preopContainer" class="sectionContainer">
                                <div class="sectionTitle">{{ t('lensOrdered') }}</div>

                                <div id="confirmSN">{{ t('iodreport_ConfirmSerial') }}</div>

                                <div class="sectionTable">
                                    <table id="tableStyl" style="table-layout: fixed">
                                        <tr class="headerRow">
                                            <th class="colSerial">
                                                {{ t('preopreport_SerialNum') }}
                                            </th>
                                            <th class="colModel">
                                                {{ t('preopreport_Model') }}
                                            </th>
                                            <th
                                                :class="{
                                                    colVersionToric: iodReport.eye.isToric,
                                                    colVersion: !iodReport.eye.isToric,
                                                }"
                                            >
                                                {{ t('version') }}
                                            </th>
                                            <th class="colSEQ">
                                                {{ t('preopreport_Exp_SEQ') }}
                                            </th>
                                            <th class="colREF">
                                                {{ t('iodreportpopup_Exp_Ref') }}
                                            </th>
                                            <th
                                                :class="{
                                                    sphColToric: iodReport.eye.isToric,
                                                    sphColNT: !iodReport.eye.isToric,
                                                    sidColSph: !iodReport.eye.isToric,
                                                    smallCol: !iodReport.eye.isToric,
                                                    smallColToric: iodReport.eye.isToric,
                                                }"
                                            >
                                                {{ t('preopreport_Sphere') }}
                                            </th>
                                            <th
                                                v-if="iodReport.eye.isToric"
                                                :class="{
                                                    smallCol: !iodReport.eye.isToric,
                                                    smallColToric: iodReport.eye.isToric,
                                                    cylCol: !iodReport.eye.isToric,
                                                    cylColToric: iodReport.eye.isToric,
                                                }"
                                            >
                                                {{ t('preopreport_Cylinder') }}
                                            </th>
                                            <th
                                                v-if="iodReport.eye.isToric"
                                                :class="{
                                                    axisCol: !iodReport.eye.isToric,
                                                    axisColToric: iodReport.eye.isToric,
                                                }"
                                            >
                                                {{ t('preopreport_Axis') }}
                                            </th>
                                            <th
                                                v-if="iodReport.eye.isToric"
                                                :class="{
                                                    axisColSpacer: !iodReport.eye.isToric,
                                                    axisColSpacerToric: iodReport.eye.isToric,
                                                }"
                                            ></th>
                                        </tr>
                                        <tr>
                                            <td class="orderCell itemSerial">
                                                {{
                                                    definedAndNotBlankObj(
                                                        iodReport.eye,
                                                        'serialNum',
                                                        '—'
                                                    )
                                                }}
                                            </td>
                                            <td
                                                class="orderCell itemModel"
                                                :class="{
                                                    [highlightedText(iodReport.eye, 'model')]: true,
                                                }"
                                            >
                                                <span>{{ parseLensLength() }}</span>
                                                <span class="highlightedCell">{{
                                                    lensLengthDiff()
                                                }}</span>
                                            </td>
                                            <td class="orderCell itemVersion">
                                                {{
                                                    definedAndNotBlankObj(
                                                        iodReport.eye,
                                                        'version',
                                                        '—'
                                                    )
                                                }}
                                            </td>
                                            <td class="orderCell">
                                                {{
                                                    formatWithPadding(
                                                        checkForHighlighting('expSeq'),
                                                        decimalSeparator,
                                                        2,
                                                        4
                                                    )
                                                }}
                                            </td>
                                            <td class="orderCell">
                                                {{ expRefFormatted }}
                                            </td>
                                            <td
                                                :class="{
                                                    sphColToric: iodReport.eye.isToric,
                                                    sphColNT: !iodReport.eye.isToric,
                                                    highlightedCell: highlightedText(
                                                        iodReport.eye,
                                                        'sphere'
                                                    ),
                                                }"
                                                class="orderCell"
                                            >
                                                {{
                                                    formatWithPadding(
                                                        checkForHighlighting('sphere'),
                                                        decimalSeparator,
                                                        2,
                                                        4
                                                    )
                                                }}
                                            </td>
                                            <td
                                                v-if="iodReport.eye.isToric"
                                                class="orderCell"
                                                :class="{
                                                    [highlightedText(
                                                        iodReport.eye,
                                                        'cylinder'
                                                    )]: true,
                                                    cylCol: !iodReport.eye.isToric,
                                                    cylColToric: iodReport.eye.isToric,
                                                }"
                                            >
                                                {{
                                                    formatWithPadding(
                                                        checkForHighlighting('cylinder'),
                                                        decimalSeparator,
                                                        2,
                                                        4
                                                    )
                                                }}
                                            </td>
                                            <td
                                                v-if="iodReport.eye.isToric"
                                                class="orderCell"
                                                :class="{
                                                    [highlightedText(iodReport.eye, 'axis')]: true,
                                                    axisCol: !iodReport.eye.isToric,
                                                    axisColToric: iodReport.eye.isToric,
                                                }"
                                            >
                                                {{
                                                    formatWithPadding(
                                                        checkForHighlighting('axis'),
                                                        decimalSeparator,
                                                        0,
                                                        3,
                                                        false
                                                    )
                                                }}
                                            </td>
                                            <td
                                                v-if="iodReport.eye.isToric"
                                                :class="{
                                                    axisColSpacer: !iodReport.eye.isToric,
                                                    axisColSpacerToric: iodReport.eye.isToric,
                                                }"
                                            ></td>
                                        </tr>
                                    </table>

                                    <!--<div v-if="iodReport.eye.primary" id="primaryText">
                                        ({{ t('iodreport_Primary') }})
                                    </div>
                                    <div v-else id="primaryText">({{ t('iodreport_Backup') }})</div>-->

                                    <div id="placeLabelContainer">
                                        <img src="@/assets/reports/labelBorder.png" />
                                        <div id="placeLabel">
                                            {{ t('iodreport_PlaceLabel') }}
                                        </div>
                                    </div>

                                    <div id="diagram" :class="isOd ? 'od' : 'os'">
                                        <img
                                            v-if="eyeOutlineVal == true && isOd == true"
                                            id="eyeImg"
                                            src="@/assets/reports/EYE_SHAPE_MIRRORED.png"
                                        />
                                        <img
                                            v-if="eyeOutlineVal == true && isOd != true"
                                            id="eyeImg"
                                            src="@/assets/reports/EYE_SHAPE.png"
                                        />

                                        <img
                                            v-if="isOd"
                                            id="stinImg"
                                            src="@/assets/reports/SNIT.png"
                                        />
                                        <img v-else id="snitImg" src="@/assets/reports/STIN.png" />

                                        <img
                                            v-if="mendezRingVal == true"
                                            id="mendezImg"
                                            src="@/assets/reports/Mendez.png"
                                        />

                                        <div
                                            id="deviceCenterLineContainer"
                                            :style="deviceOrientationCssVar"
                                        >
                                            <img id="deviceImg" src="@/assets/reports/DEVICE.png" />
                                            <div id="deviceCenterLine" v-if="zeroResult">
                                                _ _ _ _ _ _ _ _ _ _ _ _
                                            </div>
                                            <div id="deviceCenterLine" v-else>
                                                _ _ _ _ _ _ _ _ _ _ _ _ _
                                            </div>
                                            <div
                                                id="deviceCenterLineText"
                                                :class="{
                                                    zeroResult: zeroResult,
                                                    invalidValue:
                                                        deviceDegrees > 22 && deviceDegrees < 158,
                                                }"
                                            >
                                                {{ deviceDegrees }}&deg;
                                            </div>
                                        </div>

                                        <div
                                            id="deviceOrientationContainer"
                                            :class="{
                                                zeroResult: zeroResult,
                                                mirrored: deviceDegrees < 90,
                                            }"
                                        >
                                            <div
                                                id="deviceOrientationText"
                                                :class="{
                                                    zeroResult: zeroResult,
                                                    invalidValue: deviceOrientationText > 22,
                                                }"
                                            >
                                                {{ deviceOrientationText }}&deg;
                                            </div>
                                            <img
                                                v-if="deviceDegrees < 90"
                                                id="deviceOrientationImg"
                                                src="@/assets/reports/orientationPointerMirrored.png"
                                            />
                                            <img
                                                v-else
                                                id="deviceOrientationImg"
                                                src="@/assets/reports/orientationPointer.png"
                                            />
                                        </div>
                                    </div>

                                    <div id="bottomRow">
                                        <div id="bottomRow_leftCol">
                                            <div v-if="deviceDegrees == 0">
                                                <div
                                                    id="bottomRow_leftCol_Row1"
                                                    v-if="iodReport.eye.isToric"
                                                >
                                                    {{ t('iodreport_NoRot') }}
                                                </div>
                                            </div>

                                            <div v-else>
                                                <div
                                                    id="bottomRow_leftCol_Row1"
                                                    :class="
                                                        iodReport.eye.isToric ? '' : 'sphericLens'
                                                    "
                                                    v-html="
                                                        t(
                                                            isClockwise
                                                                ? 'iodreport_RotateLensClockwise'
                                                                : 'iodreport_RotateLensCounterClockwise',
                                                            {deviceOrientationText, deviceDegrees}
                                                        )
                                                    "
                                                ></div>
                                            </div>

                                            <div id="bottomRow_leftCol_Row4 pt-2">
                                                <span class="redText">
                                                    <span>{{
                                                        t(
                                                            iodReport.eye.piRequired
                                                                ? 'iodreport_PiRequired'
                                                                : 'iodreport_NoPiRequired'
                                                        )
                                                    }}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div id="bottomRow_rightCol">
                                            <div id="sphericText" v-if="!iodReport.eye.isToric">
                                                {{ t('iodreport_SphericText') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="footer">
                    <div id="footerLeft">
                        <img id="footerImg" src="@/assets/StaarSurgical-horiz.png" />
                        <div>
                            {{
                                iodReport.iodTimeDate | date({format: dateFormat, isUTC: false})
                            }}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                            {{ definedAndNotBlankObj(this.currentUser, 'firstName', '—') }}
                            {{ definedAndNotBlankObj(this.currentUser, 'lastName', '—') }}
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div id="calculationVersionContainer">
                        <img id="lensTypeImg" :src="lensTypeImgUrl" />
                        <div>
                            {{ t('preopreport_CalculatedWith') }} {{ iodReport.calculationVersion }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {DEFAULT_DATE_FORMAT} from '@/config';
import {
    formatWithPadding,
    genderHandling,
    namesFormatter,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import {definedAndNotBlankObj} from '@/utilities/object';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';
import {generatePdfDocument, displayPdfDocument} from '@/utilities/pdfUtils';
import {getLogo, iodReportSelectorList} from '@/constants/report';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import date from '@/filters/date';

export default {
    name: 'IodReport',
    components: {
        SpinnerOverlay,
    },
    data() {
        return {
            lensOverride_SerialNumVal: false,
            doneLoading: false,
            error: false,
            deviceDegrees: 0,
            dontLaunchPrintDialog: false,
            eyeOutlineVal: true,
            mendezRingVal: true,
            highlightedKeys: '',
            iodReport: null,
            hideReport: false,
            debugVal: false,
            embeddedVal: false,
            iodReportDebug: {
                firstName: 'Bartholomewz',
                lastName: 'Featherstonethrowz',
                gender: 'M',
                customerOcosId: '987654321z',
                dob: '11/21/1980z',
                yearsOld: 39,
                patientOcosId: '123456789z',
                customerName: 'West Coast Clinicz',
                surgeon: 'Dr. John Medinaz',
                surgeonOcosId: '5678901z',
                surgeryDate: '2/2/2022z',
                calculationVersion: 'v6.00USz',
                iodTimeDate: '3/14/2022z 11:34',
                eye: {
                    name: 'OD',
                    primary: true,
                    isToric: true,

                    targetLens: 'Toric Myopic 13.7 -07.50 / +2.0 X090',
                    targetLensSphere: -12,
                    targetLensCylinder: 3,
                    targetLensAxis: -5,
                    targetLensExpSeq: 0.11,

                    lengthDiff: true,
                    lensType: 'EvoVisianIcl',
                    serialNum: 'T12345xxz',
                    model: 'VTICM5_12.1',
                    version: 'EVO+ Visian ICL',
                    sphere: -1,
                    cylinder: 2,
                    axis: -5,
                    expRef: '-00.25',
                    expSeq: 0.1,
                    piRequired: false,

                    previousInterventions: [
                        'Refractive Surgery (LVC)',
                        'Non-refractive corneal surgery',
                        'IOL, CLE, Cataract',
                    ],
                },
            },
            pagePrinted: false,
        };
    },
    computed: {
        ...mapGetters({
            iodReportRemote: 'iodreport/iodReport',
        }),
        ...mapGetters('user', ['activeCustomer', 'currentUser']),
        ...mapGetters('permissions', ['permissions']),
        hasPatientPersonalDataPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PERSONAL_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            });
        },
        dateFormat() {
            return `${DEFAULT_DATE_FORMAT} HH:mm`;
        },
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
        targetLensDescription() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.iodReport.eye, 'targetLens', ''),
                this.decimalSeparator
            );
        },
        targetLensPrescription() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.iodReport.eye, 'targetLensPrescription', ''),
                this.decimalSeparator
            );
        },
        expRefFormatted() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.iodReport.eye, 'expRef', ''),
                this.decimalSeparator
            );
        },
        lensTypeImgUrl() {
            const brandLogo = getLogo(this.iodReport.brandLogo);
            return require(`@/assets/reports/${brandLogo}.png`);
        },

        deviceOrientationCssVar() {
            return {
                '--orientation-deg': `-${this.deviceDegrees}deg`,
            };
        },

        deviceOrientationText() {
            if (this.deviceDegrees < 90) {
                return this.deviceDegrees;
            } else {
                return `${180 - this.deviceDegrees}`;
            }
        },

        zeroResult() {
            if (this.deviceDegrees == 0) {
                return true;
            }

            return false;
        },

        isClockwise() {
            if (this.deviceDegrees < 90) {
                return false;
            } else {
                return true;
            }
        },

        isOd: {
            set: function (value) {
                if (value) this.iodReport.eye.name = 'OD';
                else this.iodReport.eye.name = 'OS';
            },

            get: function () {
                if (this.iodReport.eye.name.toLowerCase() == 'od') return true;

                return false;
            },
        },
    },
    props: {
        embedded: {
            type: Boolean,
            default: false,
        },

        eyeOutline: {
            type: Boolean,
            default: true,
        },

        mendezRing: {
            type: Boolean,
            default: true,
        },

        debug: {
            type: Boolean,
            default: false,
        },

        lensOrderId: {
            type: String,
            default: undefined,
        },

        lensOverride_SerialNum: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        formatWithPadding,
        genderHandling,
        definedAndNotBlankObj,
        namesFormatter,
        date,
        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },

        /** Generate a pdf report and display it in an iframe */
        async printReport() {
            const pdfBlob = await generatePdfDocument(
                '#main',
                iodReportSelectorList,
                this.t('reportTitle'),
                this.$html2canvas,
                this.debug
            );
            this.hideReport = true;
            displayPdfDocument(pdfBlob);
        },

        highlightedText(obj, key) {
            if (obj == undefined) return '';

            if (obj[key] == undefined) return '';

            if (typeof obj[key] == 'string' && obj[key].length == 0) return '';

            let highlightedKeys = this.highlightedKeys.split(',');

            if (highlightedKeys.find((element) => element == key)) {
                return 'highlightedCell';
            } else {
                return '';
            }
        },

        parseLensLength() {
            let output = this.definedAndNotBlankObj(this.iodReport.eye, 'model', '—');

            if (this.iodReport.eye.lengthDiff) {
                const re = new RegExp('(.*?)([.0-9]+$)');
                let reResult = output.match(re);
                return reResult[1];
            } else {
                return output;
            }
        },

        lensLengthDiff() {
            let output = this.definedAndNotBlankObj(this.iodReport.eye, 'model', '—');

            if (this.iodReport.eye.lengthDiff) {
                const re = new RegExp('(.*?)([.0-9]+$)');
                let reResult = output.match(re);
                return reResult[2];
            } else {
                return '';
            }
        },

        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },

        checkForHighlighting(value) {
            let output = this.definedAndNotBlankObj(this.iodReport.eye, value, '—');

            //Does value exist?
            if (
                output != '—' &&
                this.iodReport.eye[`targetLens${this.capitalizeFirstLetter(value)}`] != undefined
            ) {
                let highlightedKeys = this.highlightedKeys.split(',');

                if (
                    this.iodReport.eye[value] !=
                    this.iodReport.eye[`targetLens${this.capitalizeFirstLetter(value)}`]
                ) {
                    //Is value in highlightedKeys already?
                    let found = false;
                    if (highlightedKeys.find((item) => item == value)) found = true;

                    //Add value if not found
                    if (!found) highlightedKeys.push(value);
                } else {
                    //Remove value if found
                    highlightedKeys = highlightedKeys.filter((item) => item != value);
                }

                this.highlightedKeys = highlightedKeys.join();
            }

            return output;
        },

        updateIodCalculate(targetLensAxis, axis) {
            this.$store
                .dispatch('iodreport/fetchIodCalculate', {
                    targetAxis: targetLensAxis,
                    eyeAxis: axis,
                })
                .then((response) => {
                    if (response.error) {
                        this.error = true;
                        console.log('Error: updateIodCalculate', JSON.stringify(response));
                    } else {
                        this.deviceDegrees = response.result;
                    }

                    this.doneLoading = true;
                })
                .catch((e) => {
                    this.error = true;
                    console.log('Error: updateIodCalculate: fetch failed.');
                });
        },
    },

    updated() {
        let lockSection = false;
        if (!this.embeddedVal && !this.pagePrinted && !this.debug) {
            var styleSheet = document.createElement('style');
            styleSheet.innerText = `
                body {
                    overflow: hidden;
                }
            `;
            document.head.appendChild(styleSheet);

            let timer = setInterval(() => {
                if (!lockSection && !this.pagePrinted) {
                    lockSection = true;
                    this.pagePrinted = true;

                    if (this.error) {
                        clearInterval(timer);
                    } else if (this.doneLoading) {
                        this.printReport();
                        clearInterval(timer);
                    }

                    lockSection = false;
                }
            }, 500);
        }
        this.$emit('updated');
    },

    async mounted() {
        let fetchIodReportObj = {};

        if (this.lensOrderId != undefined) {
            //We are running embedded so use this value
            fetchIodReportObj.lensOrderId = this.lensOrderId;
        } else {
            //We are running as a page so use this value
            fetchIodReportObj.lensOrderId = this.$route.params.lensOrderId;
        }

        if (this.debugVal) console.log(this.$route.params);
        if (this.debugVal) console.log(this.$route.query);

        if (this.$route.query?.lensOverride_SerialNum) {
            this.lensOverride_SerialNumVal = this.$route.query?.lensOverride_SerialNum;
        } else if (this.lensOverride_SerialNum) {
            this.lensOverride_SerialNumVal = this.lensOverride_SerialNum;
        }

        if (
            //Todo - How does the lens serial override affect this?
            fetchIodReportObj.lensOrderId == undefined ||
            fetchIodReportObj.lensOrderId.length == 0
        ) {
            //We have a problem so alert
            //Todo - Does this go to toast?
            alert('Error: The lensOrderId is empty');
        } else if (fetchIodReportObj.lensOrderId == 'zzz') {
            this.iodReport = this.iodReportDebug;
        } else if (this.lensOverride_SerialNumVal) {
            fetchIodReportObj.serialNum = this.lensOverride_SerialNumVal;
            await this.blockingRequest('iodreport/fetchIodReportWithOverride', fetchIodReportObj);
            this.iodReport = this.iodReportRemote;

            if (this.iodReport.overrideSerialError != '') this.error = true;

            //Only Torics currently supported
            if (!this.iodReport.eye.isToric) this.error = true;
        } else {
            await this.blockingRequest('iodreport/fetchIodReport', fetchIodReportObj);
            this.iodReport = this.iodReportRemote;
        }

        if (this.$route.query?.eyeOutline == 'false') {
            this.eyeOutlineVal = false;
        }

        if (this.$route.query?.mendezRing == 'false') {
            this.mendezRingVal = false;
        }

        this.embeddedVal = this.embedded;
        //if (this.$route.query.debug) this.debugVal = true;

        if (this.$route.query?.dontLaunchPrintDialog == 'true') {
            this.dontLaunchPrintDialog = true;
        }
    },

    created() {
        if (!this.embeddedVal) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('bodyBackgroundColorOverride');
        }
    },

    destroyed() {
        if (!this.embeddedVal) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.remove('bodyBackgroundColorOverride');
        }
    },

    watch: {
        eyeOutline: function (newVal, oldVal) {
            this.eyeOutlineVal = newVal;
        },
        mendezRing: function (newVal, oldVal) {
            this.mendezRingVal = newVal;
        },
        lensOverride_SerialNum: function (newVal, oldVal) {
            this.iodReport.eye.serialNum = newVal;
        },
        embedded: function (newVal, oldVal) {
            this.embeddedVal = newVal;
        },
        'iodReport.eye.targetLensAxis': function (newVal, oldVal) {
            this.updateIodCalculate(newVal, this.iodReport.eye.axis);
        },
        'iodReport.eye.axis': function (newVal, oldVal) {
            this.updateIodCalculate(this.iodReport.eye.targetLensAxis, newVal);
        },
        error: function (newVal, oldVal) {
            this.$emit('error', newVal);
        },
    },
};
</script>
<style lang="scss" scoped>
#generatingReportContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

#main > * {
    width: 1436px;
}

#printReportBtn {
    position: absolute;
    z-index: 2000;
}

#main {
    padding-top: 40px;
    padding-left: 50px;
    padding-bottom: 20px;
    background-color: white;
    width: 1500px;
    font-size: var(--small);
    transform: unset;
    z-index: 0;
}

#generatingReportContainer.reportContainerErrorEmbedded {
    top: 725px;
}

img#headerImg {
    margin-top: 20px;
    margin-bottom: 0px;
    width: 235px;
}

.eyeResultHeaderImg {
    width: 50px;
    float: left;
    margin-right: 12px;
    margin-top: -2px;
}

div#headerRight {
    font-size: var(--large);
    color: #5b5b5b;
    font-weight: bold;
    float: right;
    margin-top: 20px;
}

div#titleContainer {
    border-bottom: 5px solid black;
    margin-right: 62px;
}

.names {
    color: #616469;
    font-size: var(--large);
}

div#patientDataCol2 {
    display: inline-block;
    margin-left: 31px;
}

div#patientDataCol1 {
    float: left;
    width: 688px;
}

.patientDataItem {
    padding: 20px 0px;
    clear: both;
}

.patientDataLabel {
    color: #616469;
    float: left;
    width: 150px;
    font-size: var(--small);
}

.eyeContainer {
    border: 5px solid black;
    width: 1445px;
    float: left;
    margin-right: 75px;
}

.patientDataValue {
    float: left;
    font-weight: bold;
    font-size: var(--medium);
    margin-top: -9px;
}

.noEmphasis {
    color: #91979d;
    font-size: var(--small);
}

.eyeTitle {
    font-size: 4.5rem;
    font-weight: bold;
    margin: 5px 45px 0px 25px;
    float: left;
}

.sectionTitle {
    font-size: var(--large);
    font-weight: bold;
    margin-left: 25px;
    padding-top: 53px;
    padding-bottom: 18px;
}

.eyeResultHeader {
    height: 94px;
    border-bottom: 5px solid black;
    display: flex;
    align-items: center;
}

div#resultsContainer {
    clear: both;
    padding-top: 35px;
}

th {
    color: rgba(0, 0, 0, 0.5);
    font-size: var(--small);
    text-align: center;
}

table {
    width: 96%;
    padding: 10px;
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 10px 10px 10px 25px;
    font-size: inherit;
}

tr.headerRow {
    background-color: #f9f9f9;
}

td {
    font-weight: bold;
    text-align: center;
}

th,
td {
    padding: 12px;
}

div#patientDataContainer {
    padding-top: 8px;
}

div#pageHeader {
    margin-bottom: 40px;
}

#confirmSN {
    margin-left: 25px;
    color: red;
    font-weight: bold;
    font-size: var(--large);
    padding-bottom: 14px;
}

#primaryText {
    font-weight: bold;
    font-size: var(--medium);
    margin-left: 47px;
    color: #616469;
    margin-top: -5px;
}

#eyeResultHeaderText3 {
    font-size: var(--medium);
    color: grey;
    text-align: center;
    font-weight: bold;
}

#eyeResultHeaderText4 {
    font-size: var(--medium);
    font-weight: bold;
    text-align: right;
    flex-grow: 2;
    padding-left: 5px;
}

#eyeResultHeaderText5 {
    font-size: var(--medium);
    font-weight: bold;
    padding-right: 44px;
    padding-left: 5px;
    width: fit-content;
    text-align: right;
    min-width: fit-content;
}

#eyeResultHeaderText5.eyeResultHeaderText5SID {
    padding-right: 75px;
}

#lensTypeImg {
    width: 179px;
    margin-left: 20px;
    margin-bottom: 11px;
}

.highlightedCell,
.highlightedCell > *,
.highlightedCell > .unitOfMeasure {
    color: red;
}

#diagram {
    width: 100%;
    height: 780px;
    position: relative;
}

#stinImg,
#snitImg {
    z-index: 1;
    width: 1282px;
    position: absolute;
    /*top: 11px;
    left: 26px;*/
    top: 9px;
    left: 31px;
}

#stinImg {
    left: 5px;
}

#eyeImg {
    width: 1220px;
    position: absolute;
    top: 116px;
    left: 0px;
}

#mendezImg {
    z-index: 3;
    height: 542px;
    position: absolute;
    top: 155px;
    left: 400px;
}

#deviceImg {
    width: 347px;
}

#diagram.os {
    margin-left: 61px;

    #eyeImg {
        width: 1220px;
        left: 84px;
        top: 120px;
    }

    #deviceOrientationContainer {
        left: 303px;
    }

    #deviceOrientationContainer.mirrored {
        left: 1022px;
    }
}

#diagram.od {
    margin-left: 95px;

    #mendezImg {
        left: 374px;
    }

    #deviceImg {
        left: 471px;
    }

    #deviceImg {
        left: 455px;
    }

    #deviceCenterLineContainer {
        left: 471px;
    }
}

#bottomRow {
    height: 254px;
}

#bottomRow_leftCol {
    width: 704px;
    float: left;
    font-size: var(--large);
    line-height: 3.6rem;
    margin-left: 25px;
    margin-bottom: 15px;
    position: relative;
    height: 200px;
    /*background-color: aliceblue;*/
    /*font-weight: bold;*/
    top: 30px;
}

#bottomRow_leftCol_Row4 {
    position: absolute;
    bottom: 0px;
    font-weight: bold;
}

#bottomRow_rightCol {
    float: right;
}

#sphericText {
    font-size: 24px;
    color: #91979d;
    font-weight: bold;
    width: 683px;
    margin-top: 147px;
}

#rightEye {
    float: left;
    margin-right: 60px;
}

#rightEye,
#leftEye {
    width: 280px;
    border: 10px solid white;
}

#leftEye {
    float: left;
    margin-right: 10px;
}

#leftEyeImg,
#rightEyeImg {
    opacity: 0.5;
    width: 260px;
}

#rightEyeImg.eyeActive,
#leftEyeImg.eyeActive {
    opacity: 1;
}

#rightEye.eyeSelected,
#leftEye.eyeSelected {
    border: 10px solid black;
}

.redText {
    color: red;
}

.unitOfMeasure {
    font-size: var(--small);
}

#deviceCenterLine {
    width: 567px;
    position: relative;
    top: -166px;
    left: -84px;
    font-size: 53px;
    font-weight: bold;
    color: #0066cc;
    width: 600px;
}

#deviceCenterLineText {
    position: relative;
    color: white;
    background-color: #0066cc;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: var(--medium);
    transform: rotate(90deg);
    top: -196px;
    left: 438px;
    height: 35px;
    line-height: 28px;
}

#deviceCenterLineContainer {
    position: absolute;
    top: 313px;
    left: 494px;
    z-index: 4;
    transform: rotate(var(--orientation-deg));
    width: 348px;
    height: 230px;
}

#deviceOrientationText {
    color: white;
    background-color: green;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: var(--medium);
}

#deviceOrientationContainer {
    position: absolute;
    top: 304px;
    left: 284px;
    z-index: 9;
}

#debugForm {
    position: absolute;
    z-index: 10;
    top: 857px;
    background-color: aliceblue;
    left: 1544px;
    width: 351px;
}

#footer {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-top: 24px;
    margin-right: 57px;
    padding-bottom: 40px;
}

img#footerImg {
    width: 424px;
    padding-top: 15px;
    padding-bottom: 16px;
}

#calculationVersionContainer {
    margin-top: 15px;
}

.orderCell {
    font-size: var(--medium);
}

#bottomRow_rightCol {
    margin-top: 18px;
}

.sphericLens {
    opacity: 0;
}

.zeroResult {
    display: none;
}

#deviceOrientationContainer.mirrored {
    left: 993px;
}

#debugForm * {
    font-size: var(--medium);
}

#deviceOrientationText.invalidValue,
#deviceCenterLineText.invalidValue {
    color: white;
    background-color: red;
}

#generatingReport {
    font-size: 50px;
}

.previousInterventionsContainer {
    margin-right: 9px;
    margin-top: 9px;
    text-align: center;
    font-size: var(--small);
    margin-top: auto;
    margin-bottom: auto;
    width: 416px;
}

.previousInterventionsContainer.previousInterventionsContainerSID {
    width: 421px;
}

#placeLabelContainer {
    margin-left: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: lightgrey;
    font-size: var(--medium);

    // Needs to be 3.3125" by 1" when printed
    width: 548.62px;
    height: 187.4px;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

h2 {
    font-size: var(--large);
}

p,
.h5 {
    font-size: var(--small);
}

.smallCol {
    width: 70px;
}

.smallColToric {
    width: 42px;
}

.axisCol {
    width: 50px;
    padding-left: 3px;
}

.axisColToric {
    width: 28px;
    padding-left: 3px;
}

.axisColSpacer {
    width: 13px;
    padding-left: 0px;
    padding-right: 0px;
}

.axisColSpacerToric {
    width: 7px;
    padding-left: 0px;
    padding-right: 0px;
}

.cylCol {
    padding-left: 4px;
    padding-right: 7px;
    width: 55px;
}

.cylColToric {
    padding-left: 8px;
    padding-right: 4px;
}

.sphColToric {
    padding-right: 3px;
}

.sphColNT {
    padding-right: 50px;
}

#tableStyl {
    width: 100%;
    margin: 10px 0px;
}

.colSerial {
    text-align: left;
    padding-left: 35px;
    width: 118px;
}

.itemSerial {
    text-align: left;
    padding-left: 40px;
}

.colModel {
    text-align: left;
    width: 100px;
}

.itemModel {
    text-align: left;
    width: 100px;
}

.colVersion {
    width: 240px;
    text-align: left;
}

.colVersionToric {
    width: 135px;
    text-align: left;
}

.itemVersion {
    text-align: left;
}

.colSEQ,
.sidColSph {
    width: 90px;
}

.colREF {
    width: 150px;
}
.z-index-999 {
    z-index: 999;
}
</style>
<style>
:root {
    --small: 1.25rem;
    --medium: 1.65rem;
    --large: 2.25rem;
}

body.bodyBackgroundColorOverride {
    background-color: white;
}
</style>
